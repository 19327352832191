import { Link } from 'react-router-dom'
import style from './LinkActivityWrapper.module.scss'

const LinkActivityWrapper = ({ children, link }) => {
  return (
    <Link className={style.linkActivityWrapper} to={link}>
      {children}
    </Link>
  )
}

export default LinkActivityWrapper
