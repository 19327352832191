import BackBtn from '../../common/BackBtn/BackBtn'
import CoinsBalance from '../../common/CoinBalance/CoinsBalance'
import RequestInfo from './RequestInfo/RequestInfo'
import RequestHint from './RequestHint/RequestHint'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { useEffect, useState } from 'react'
import style from './TransferConfirm.module.scss'
import { useSearchParams } from 'react-router-dom'
import {
  clearCashier,
  setCashierRecipient,
} from '../../../store/transactionSlice'
import { t } from 'i18next'

const tg = window.Telegram.WebApp

const TransferConfirm = () => {
  const user = useSelector((state) => state.user.user)
  const [URLSearchParams] = useSearchParams()
  const [step, setStep] = useState('wait')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const amount = URLSearchParams.get('amount')
  const pointId = URLSearchParams.get('pointId')
  const price = URLSearchParams.get('price')

  const getPointInfo = async () => {
    dispatch(setCashierRecipient({}))
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/user/get_company_info/${pointId}?user_id=${user.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token_api}`,
          },
        }
      )
      dispatch(setCashierRecipient(data))
    } catch (error) {
      setErrorMessage('Some error occured')
    }
  }

  const confirmRequest = async () => {
    setStep('loading')
    try {
      const { data } = await axios.post(
        `https://sc.travelingo.pro/api/set_spend_transaction?point_id=${pointId}&amount=${amount}&price=${price}&user_id=${user.user_id}&set_cashback=1`
      )
      if (data.status === 'error') {
        setErrorMessage('+-+ server: ' + JSON.stringify(data.data.error))
        setStep('error')
      } else {
        setStep('done')
      }
    } catch (error) {
      console.log(error)
      setErrorMessage('+-+ axios: ' + JSON.stringify(error))
      setStep('error')
    }
  }

  const redirectToMain = () => {
    navigate('/')
  }

  useEffect(() => {
    if (user.token_api && user.user_id) {
      getPointInfo()
    }
  }, [user])

  useEffect(() => {
    return () => dispatch(clearCashier())
  }, [])

  return (
    <div className={style.notificationsItemPage}>
      <div className={style.header}>
        <BackBtn />
        <CoinsBalance />
      </div>
      <RequestInfo step={step} />
      <RequestHint step={step} errorMessage={errorMessage} />
      <button
        className={style.notificationsItemPageSubmit}
        onClick={step === 'wait' ? confirmRequest : redirectToMain}
        disabled={step === 'loading'}
      >
        {step === 'wait' ? t('ConfirmLabel') : t('ToMainLabel')}
      </button>
    </div>
  )
}

export default TransferConfirm
