import { useState } from 'react'
import ActivityItem from './ActivityItem/ActivityItem'
import { ReactComponent as ArrowIcon } from './images/chevron.svg'
import { AnimatePresence } from 'framer-motion'
import style from './RecentActivity.module.scss'
import { useSelector } from 'react-redux'
import NewActivityItem from './NewActivityItem/NewActivityItem'
import { t } from 'i18next'

const RecentActivity = () => {
  const [isActivityOpen, setIsActivityOpen] = useState(false)
  const activity = useSelector((state) => state.user.recentActivity)
  const user = useSelector((state) => state.user.user)

  const handleToggleActivity = () => {
    setIsActivityOpen(!isActivityOpen)
  }

  return (
    <div className={style.recentActivity}>
      <div className={style.header}>
        <button
          onClick={handleToggleActivity}
          className={`${style.title} ${isActivityOpen ? style.open : ''}`}
          disabled={activity.isLoading}
        >
          <span className={style.titleName}>{t('RecentActivityTitle')}</span>
          <ArrowIcon className={style.icon} />
        </button>
      </div>
      <div className={style.wrapper}>
        <AnimatePresence>
          {user.wait_transaction_count !== 0 ? (
            <NewActivityItem isSingle={user.wait_transaction_count <= 1} />
          ) : (
            ''
          )}
          {activity.transactions
            .slice(0, isActivityOpen ? undefined : 3)
            .map((activityItem) => {
              return (
                <ActivityItem
                  key={activityItem.id}
                  amount={activityItem.amount}
                  from_name={activityItem.from_name}
                  to_name={activityItem.to_name}
                  to_address={activityItem.to_address}
                  from_address={activityItem.from_address}
                  date={activityItem.date}
                  isReceived={activityItem.isReceived}
                  message={activityItem.message}
                  transactionType={activityItem.transaction_type}
                  status={activityItem.status}
                />
              )
            })}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default RecentActivity
