import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hash: {},
  isLoading: false,
  recipient: {},
  amount: '',
}

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setAmount: (state, action) => {
      state.recipient = {
        ...state.recipient,
        amount: action.payload,
      }
    },
    setRecipient: (state, action) => {
      state.recipient = action.payload
    },
    setHash: (state, action) => {
      state.recipient = action.payload
    },
  },
})

export const { setIsLoading, setRequestAmount, setRequestRecipient, setHash } =
  requestSlice.actions

export default requestSlice.reducer
