import { useEffect, useState } from 'react'
import style from './MainPage.module.scss'
import CoinsBalance from './CoinsBalance/CoinsBalance'
import MainBtns from './MainBtns/MainBtns'
import HorizontalLine from '../UI/HorizontalLine/HorizontalLine'
import RecentActivity from './RecentActivity/RecentActivity'
import { useDispatch, useSelector } from 'react-redux'
import {
  setRecentActivity,
  setRecentActivityIsLoading,
  setUser,
  setWallet,
} from '../../store/userSlice'
import {
  getRecentActivity,
  getUserInfo,
  getUserWallet,
} from '../../hooks/getUserInfo'
import ErrorMessage from '../common/ErrorMessage/ErrorMessage'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'
import i18n from '../../i18n'

//@ts-ignore
const tg = window.Telegram.WebApp

const MainPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const user = useSelector((state) => state.user.user)
  const activity = useSelector((state) => state.user.recentActivity)
  const dispatch = useDispatch()

  const loadUser = async () => {
    setIsLoading(true)
    try {
      const user = await getUserInfo(
        tg.initDataUnsafe.user.username,
        tg.initDataUnsafe.user.id
      )
      dispatch(setUser(user))
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const loadRecentActivity = async () => {
    dispatch(setRecentActivityIsLoading(true))
    try {
      const body = {
        user_id: user.user_id,
        token_api: user.token_api,
        wallet_address: user.wallet_address,
        status: ['cancel', 'ok'],
        from: 0,
        to: 9,
      }
      const activity = await getRecentActivity(body)
      dispatch(setRecentActivity(activity))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setRecentActivityIsLoading(false))
    }
  }

  const loadWallet = async () => {
    setIsLoading(true)
    try {
      const wallet = await getUserWallet(user.wallet_address)
      if (wallet.status === 'ok') {
        dispatch(setWallet(wallet.data))
        loadRecentActivity()
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!user.token_api) {
      loadUser()
    }
  }, [])

  useEffect(() => {
    if (user.wallet_address) {
      loadWallet()
    }
  }, [user.wallet_address])

  useEffect(() => {
    if (!localStorage.getItem('isLanguageSet')) {
      i18n.changeLanguage(tg?.initDataUnsafe?.user?.language_code)
      localStorage.setItem('isLanguageSet', true)
    }
  }, [])

  return (
    <PageTransitionWrapper side='top'>
      <div className={style.mainPage}>
        <div className={style.wrap}>
          <CoinsBalance isLoading={isLoading} />
          {errorMessage ? (
            <div className={style.errorWrapper}>
              <ErrorMessage message={errorMessage} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={style.wrap}>
          <MainBtns />
        </div>
        <div className={style.wrap}>
          <HorizontalLine />
        </div>
        <div className={style.wrap}>
          {isLoading || activity.isLoading ? (
            <span className={style.loadingPlaceholder}>
              {t('MainPageRecentActivityLoadingLabel')}
            </span>
          ) : activity.transactions.length ? (
            <RecentActivity />
          ) : (
            <span className={style.emptyPlaceholder}>
              {t('RecentActivityPlaceholder')}
            </span>
          )}
        </div>
      </div>
    </PageTransitionWrapper>
  )
}

export default MainPage
