import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as BellIcon } from './images/bell-icon.svg'
import style from './RequestDebiting.module.scss'
import { useSelector } from 'react-redux'
import { t } from 'i18next'

const RequestDebiting: FC = () => {
  const user = useSelector((state: any) => state.user.user)
  const requestsAmount = useSelector(
    (state: any) => state.user.user.wait_transaction_count
  )

  return (
    <Link to='/requests' className={style.wrap}>
      <div className={style.left}>
        <div className={style.notification}>
          <BellIcon />
          <div className={style.new}></div>
        </div>
        <span className={style.title}>
          {t('DebitingRequestLabel')} ({requestsAmount})
        </span>
      </div>
      <div className={style.right}>
        <svg
          width='21'
          height='15'
          viewBox='0 0 21 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.7071 8.11384C21.0976 7.72332 21.0976 7.09015 20.7071 6.69963L14.3431 0.335669C13.9526 -0.0548551 13.3195 -0.0548551 12.9289 0.335669C12.5384 0.726194 12.5384 1.35936 12.9289 1.74988L18.5858 7.40674L12.9289 13.0636C12.5384 13.4541 12.5384 14.0873 12.9289 14.4778C13.3195 14.8683 13.9526 14.8683 14.3431 14.4778L20.7071 8.11384ZM8.92441e-08 8.40674L20 8.40674L20 6.40674L-8.92441e-08 6.40674L8.92441e-08 8.40674Z'
            fill='#037EE5'
          />
        </svg>
      </div>
    </Link>
  )
}

export default RequestDebiting
