import { FC } from 'react'
import { ReactComponent as BellIcon } from './images/bell.svg'
import { ReactComponent as ArrowIcon } from './images/arrow.svg'
import style from './NewActivityItem.module.scss'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

interface IProps {
  isSingle: boolean
}

const NewActivityItem: FC<IProps> = ({ isSingle }) => {
  return (
    <Link className={style.item} to='/requests'>
      <div className={style.left}>
        <div className={style.image}>
          <BellIcon />
        </div>
        <div className={style.title}>
          <div className={style.new_request}>
            {isSingle
              ? t('DebitingRequestLabelSingle')
              : t('DebitingRequestLabel')}
          </div>
          <div className={style.name}></div>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.arrow}>
          <ArrowIcon />
        </div>
        <div className={style.date}></div>
      </div>
    </Link>
  )
}

export default NewActivityItem
