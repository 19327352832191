import { ReactComponent as CardIcon } from './images/card-icon.svg'
import { ReactComponent as TrvIcon } from './images/trv.svg'
import { ReactComponent as LoadingIcon } from './images/loading-icon.svg'
import { ReactComponent as DoneIcon } from './images/green-icon.svg'
import { ReactComponent as ErrorIcon } from './images/red-cross.svg'
import { motion } from 'framer-motion'
import style from './RequestInfo.module.scss'
import { t } from 'i18next'

const RequestInfo = ({ step, cashierName, amount }) => {
  return (
    <div className={style.requestInfo}>
      {step === 'loading' ? (
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, ease: 'linear', duration: 3 }}
        >
          <LoadingIcon />
        </motion.div>
      ) : step === 'wait' ? (
        <CardIcon className={style.requestInfoIcon} />
      ) : step === 'done' ? (
        <DoneIcon className={style.requestInfoIcon} />
      ) : (
        <ErrorIcon className={style.requestInfoIcon} />
      )}
      <span className={style.requestRecipientName}>{cashierName}</span>
      <span className={style.requestPrice}>
        {Number(amount).toFixed(2)}{' '}
        <TrvIcon className={style.requestRecipientTrvIcon} />
      </span>
      <span className={style.requestPriceConverted}>
        ~ {Number(amount).toFixed(2)} €
      </span>
      <span className={style.requestCommision}>{t('NoCommissionLabel')}</span>
    </div>
  )
}

export default RequestInfo
