import axios from 'axios'
import { sha256 } from 'js-sha256'
import md5 from 'md5'

const collectHash = (username, id) => {
  const stringToHash = `${process.env.REACT_APP_BOT_TOKEN}-${username}-${id}`
  const shaHash = sha256(stringToHash)
  const mdHash = md5(shaHash)
  return mdHash
}

const sendHash = async (username, id) => {
  try {
    const formData = new FormData()
    formData.append('hash', collectHash(username, id))
    formData.append('username', username)
    formData.append('id', id)
    const response = await axios.post(
      'https://api.travelingo.pro/api/user/auth_tg',
      formData,
      {
        headers: {
          Authorization: `Bearer api_for_travelingo`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export default sendHash
