import axios from 'axios'
import sendHash from './sendHash'

export const getUserInfo = async (username, userId) => {
  const { data } = await sendHash(username, userId)
  return data
}

export const getUserWallet = async (walletAddress) => {
  const formData = new FormData()
  formData.append('wallet_address', walletAddress)

  const { data } = await axios.get(
    'https://sc.travelingo.pro/api/get_wallet_info',
    {
      params: {
        wallet_address: walletAddress,
      },
    }
  )
  return data
}

export const getRecentActivity = async ({
  user_id,
  token_api,
  wallet_address,
  status,
  from,
  to,
}) => {
  const { data } = await axios.get(
    'https://api.travelingo.pro/api/user/get_transaction_history',
    {
      headers: {
        Authorization: `Bearer ${token_api}`,
      },
      params: {
        user_id: user_id,
        wallet_address: wallet_address,
        'filter_value[]': status,
        from_transaction: from,
        to_transaction: to,
      },
    }
  )
  return data
}

export const getUserInsensitiveInfo = async (recipientId) => {
  const { data } = await axios.get(
    'https://api.travelingo.pro/api/user/get_profile_short_info',
    {
      headers: {
        Authorization: `Bearer api_for_travelingo`,
      },
      params: {
        filter_value: recipientId,
      },
    }
  )
  return data
}
