import { useMemo, useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import CoinsBalance from '../common/CoinBalance/CoinsBalance'
import BackBtn from '../common/BackBtn/BackBtn'
import { QrReader } from 'react-qr-reader'
import style from './SendPage.module.scss'
import { ReactComponent as CloseIcon } from './images/close.svg'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
  setIsLoading,
  setRecipient,
  setTransactionAmount,
  setTransactionError,
} from '../../store/transactionSlice'
import { getUserInsensitiveInfo } from '../../hooks/getUserInfo'
import { ReactComponent as TravelingoIcon } from './images/travelingo-icon.svg'
import { ReactComponent as WalletIcon } from './images/wallet-icon.svg'
import { ReactComponent as UserIcon } from './images/user-icon.svg'
import { ReactComponent as QrIcon } from './images/scan-icon.svg'
import ErrorMessage from '../common/ErrorMessage/ErrorMessage'
import debounce from 'lodash.debounce'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const SendPage = () => {
  const [isQrScannerOpened, setIsQrScannerOpened] = useState(false)
  const [balanceError, setBalanceError] = useState(false)
  const [recipientId, setRecipientId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const user = useSelector((state) => state.user.user)
  const walletBalance = useSelector((state) => state.user.wallet.balance)
  const transaction = useSelector((state) => state.transaction.transactionInfo)
  const isLoading = useSelector((state) => state.transaction.isLoading)
  const recipient = useSelector((state) => state.transaction.recipient)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleOpenScanner = () => {
    setIsQrScannerOpened(!isQrScannerOpened)
  }

  const handleCloseScanner = () => {
    setIsQrScannerOpened(false)
  }

  const handleSuccessScanner = (data) => {
    const url = new URL(data)
    const params = new URLSearchParams(url.search)

    const address = params.get('address')
    setRecipientId(address)
    setIsQrScannerOpened(false)
    getRecipientInfo(address)
  }

  const handleSetRecipientAddress = (event) => {
    const value = event.target.value
    setRecipientId(value)
    debouncedSendRequest(value)
  }

  const getRecipientInfo = useCallback(async (value) => {
    dispatch(setRecipient({}))
    try {
      const response = await getUserInsensitiveInfo(value)

      if (response.status === 'error') {
        setErrorMessage(response.error)
      } else {
        dispatch(setRecipient(response))
        setErrorMessage('')
        if (response.wallet_address === user.wallet_address)
          setErrorMessage(
            'Recipient should not be the same as the current user'
          )
      }
    } catch (error) {
      setErrorMessage(t('SendPageRecipientError'))
    }
  }, [])

  const handleSetAmount = (event) => {
    dispatch(setTransactionAmount(event.target.value))
  }

  const handleSubmit = async () => {
    dispatch(setTransactionError(false))
    dispatch(setIsLoading(true))
    navigate('/transaction-sent')
    try {
      if (transaction.amount <= 0) {
        throw new Error('Please enter valid amount of coins')
      }
      const formData = new FormData()
      formData.append('from_wallet', user.wallet_address)
      formData.append('to_wallet', recipient.wallet_address)
      formData.append('amount', transaction.amount)
      await axios.post(
        'https://sc.travelingo.pro/api/set_transaction',
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setTransactionError(true))
      setErrorMessage(error.message)
    } finally {
      dispatch(setIsLoading(false))
      setRecipientId('')
    }
  }

  const isInputsNotEmpty =
    transaction.amount?.length !== 0 &&
    recipientId.length !== 0 &&
    !!Number(transaction.amount)
  const isValid =
    isInputsNotEmpty && !balanceError && !errorMessage && !isLoading

  useEffect(() => {
    if (transaction.amount > walletBalance) {
      setBalanceError(true)
    } else {
      setBalanceError(false)
    }
  }, [transaction.amount])

  const debouncedSendRequest = useMemo(() => {
    return debounce(getRecipientInfo, 500)
  }, [getRecipientInfo])

  return (
    <PageTransitionWrapper side='bottom'>
      <div className={style.sendPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <div className={style.content}>
          <div className={style.row}>
            <label className={style.rowLabel} htmlFor='name'>
              {t('SendPageRecipientLabel')}:
            </label>
            <div className={style.recipient}>
              <UserIcon className={style.userIcon} />
              {errorMessage ? (
                <span className={style.recipientName}>
                  <ErrorMessage message={errorMessage} />
                </span>
              ) : (
                <span className={style.recipientName}>
                  {errorMessage
                    ? errorMessage
                    : recipient.tg_username
                    ? recipient.tg_username
                    : recipient.email
                    ? recipient.email
                    : '...'}
                </span>
              )}
            </div>
            <div className={style.input_wrap}>
              <div className={style.icon_person}>
                <WalletIcon />
              </div>
              <input
                type='text'
                className={style.secondary}
                value={recipientId}
                onChange={handleSetRecipientAddress}
                name='name'
                placeholder={t('SendPageRecipientInput')}
              />
              <button onClick={handleOpenScanner} className={style.enable_scan}>
                <QrIcon />
              </button>
            </div>
          </div>
          <div className={style.row}>
            <label htmlFor='amount'>{t('SendPageAmountLabel')}:</label>
            <div className={style.input_wrap}>
              <input
                type='number'
                className={`${style.main} ${
                  balanceError ? style.errorNumber : ''
                }`}
                name='amount'
                placeholder=''
                value={transaction.amount}
                onChange={handleSetAmount}
              ></input>
              <div className={style.additional}>
                <TravelingoIcon />
              </div>
            </div>
            <div
              className={`${style.commentary} ${
                balanceError ? style.commentaryError : ''
              }`}
            >
              {balanceError
                ? t('InsufficientFundsLabel')
                : `~ ${Number(transaction.amount).toFixed(2)} €`}
            </div>
          </div>
          <div className={style.row}>
            <button
              onClick={handleSubmit}
              disabled={!isValid}
              className={style.btn}
            >
              {t('SendPageRecipientSubmitLabel')}
            </button>
          </div>
        </div>

        {isQrScannerOpened ? (
          <div className={style.qrWrapper}>
            <button
              className={style.qrCloseButton}
              onClick={handleCloseScanner}
            >
              <CloseIcon className={style.qrCloseButtonIcon} />
            </button>
            <QrReader
              ViewFinder={() => (
                <>
                  <div
                    style={{
                      top: '5px',
                      left: '50px',
                      position: 'absolute',
                      height: '50px',
                      width: '50px',
                      zIndex: 999,
                      borderTop: '5px solid white',
                      borderLeft: '5px solid white',
                    }}
                  />
                  <div
                    style={{
                      top: '5px',
                      right: '50px',
                      position: 'absolute',
                      height: '50px',
                      width: '50px',
                      zIndex: 999,
                      borderTop: '5px solid white',
                      borderRight: '5px solid white',
                    }}
                  />
                  <div
                    style={{
                      bottom: '5px',
                      left: '50px',
                      position: 'absolute',
                      height: '50px',
                      width: '50px',
                      zIndex: 999,
                      borderBottom: '5px solid white',
                      borderLeft: '5px solid white',
                    }}
                  />
                  <div
                    style={{
                      bottom: '5px',
                      right: '50px',
                      position: 'absolute',
                      height: '50px',
                      width: '50px',
                      zIndex: 999,
                      borderBottom: '5px solid white',
                      borderRight: '5px solid white',
                    }}
                  />
                </>
              )}
              constraints={{ facingMode: 'environment' }}
              onResult={(result, error) => {
                if (!!result) {
                  handleSuccessScanner(result?.text)
                }
                if (!!error) {
                  console.log(error?.text)
                }
              }}
              videoContainerStyle={{
                overflow: 'visible',
                padding: 0,
                position: 'relative',
              }}
              videoStyle={{ height: 'auto', position: 'relative' }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </PageTransitionWrapper>
  )
}

export default SendPage
