import { ReactComponent as WalletIcon } from './images/wallet.svg'
import { ReactComponent as ArrowIcon } from './images/arrow2.svg'
import style from './RecentActivityIcon.module.scss'

const RecentActivityIcon = ({ isReceived, type }) => {
  if ((type === 'transfer' && isReceived) || type === 'cashback') {
    return (
      <div
        className={`${style.recentActivityIcon} ${style.recentActivityIconTransferReceived}`}
      >
        <ArrowIcon />
      </div>
    )
  }
  if (type === 'transfer' && !isReceived) {
    return (
      <div
        className={`${style.recentActivityIcon} ${style.recentActivityIconTransferSend}`}
      >
        <ArrowIcon />
      </div>
    )
  }

  return (
    <div
      className={`${style.recentActivityIcon} ${style.recentActivityIconPayment}`}
    >
      <WalletIcon />
    </div>
  )
}

export default RecentActivityIcon
