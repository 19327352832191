import { useMemo, useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import style from './TransferPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
  setCashierRecipient,
  setIsLoading,
  setRecipient,
  setTransactionError,
} from '../../../store/transactionSlice'
import { setAmount } from '../../../store/transactionSlice'
import { ReactComponent as TravelingoIcon } from './images/travelingo-icon.svg'
import { ReactComponent as WalletIcon } from './images/wallet-icon.svg'
import { ReactComponent as UserIcon } from './images/user-icon.svg'
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage'
import CoinsBalance from '../../common/CoinBalance/CoinsBalance'
import BackBtn from '../../common/BackBtn/BackBtn'
import PageTransitionWrapper from '../../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const TransferPage = () => {
  const [balanceError, setBalanceError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const user = useSelector((state) => state.user.user)
  const walletBalance = useSelector((state) => state.user.wallet.balance)
  const cashierInfo = useSelector((state) => state.transaction.cashierInfo)
  const [URLSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const pointId = URLSearchParams.get('pointId')

  const getPointInfo = async () => {
    dispatch(setCashierRecipient({}))
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/user/get_company_info/${pointId}?user_id=${user.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token_api}`,
          },
        }
      )
      dispatch(setCashierRecipient(data))
    } catch (error) {
      setErrorMessage('Some error occured')
    }
  }

  const handleSetAmount = (event) => {
    dispatch(setAmount(event.target.value))
  }

  const handleSubmit = async () => {
    dispatch(setTransactionError(false))
    dispatch(setIsLoading(true))
    navigate(
      `/spendpage/transaction-sent?point_id=${pointId}&price=0&amount=${cashierInfo.amount}`
    )
    try {
      if (cashierInfo.amount <= 0) {
        throw new Error('Please enter valid amount of coins')
      }
      const { data } = await axios.post(
        `https://sc.travelingo.pro/api/set_spend_transaction?point_id=${pointId}&price=0&amount=${cashierInfo.amount}&user_id=${user.user_id}&set_cashback=0`
      )

      if (data.status === 'ok') {
        dispatch(setIsLoading(false))
      } else {
        setErrorMessage(data.data.error)
      }
    } catch (error) {
      dispatch(setTransactionError(true))
      setErrorMessage(error.message)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const isInputsNotEmpty = !!Number(cashierInfo.amount)
  const isValid = isInputsNotEmpty && !balanceError && !errorMessage

  useEffect(() => {
    if (user.token_api && user.user_id) {
      getPointInfo()
    }
  }, [user])

  useEffect(() => {
    if (cashierInfo.amount > walletBalance) {
      setBalanceError(true)
    } else {
      setBalanceError(false)
    }
  }, [cashierInfo.amount])

  return (
    <PageTransitionWrapper side='right'>
      <div className={style.sendPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <div className={style.content}>
          <div className={style.row}>
            <label className={style.rowLabel} htmlFor='name'>
              {t('SendPageRecipientLabel')}:
            </label>
            <div className={style.recipient}>
              <UserIcon className={style.userIcon} />
              {errorMessage ? (
                <span className={style.recipientName}>
                  <ErrorMessage message={errorMessage} />
                </span>
              ) : (
                <span className={style.recipientName}>
                  {errorMessage
                    ? errorMessage
                    : cashierInfo.recipient.company_name
                    ? cashierInfo.recipient.company_name
                    : '...'}
                </span>
              )}
            </div>
            <div className={style.input_wrap}>
              <div className={style.icon_person}>
                <WalletIcon />
              </div>
              <input
                type='text'
                className={style.secondary}
                value={pointId}
                name='name'
                placeholder='Scan the code or insert the wallet number'
                disabled={URLSearchParams.get('amount') === 'null'}
              />
              {/* <button onClick={handleOpenScanner} className={style.enable_scan}>
              <QrIcon />
            </button> */}
            </div>
          </div>
          <div className={style.row}>
            <label htmlFor='amount'>{t('SendPageAmountLabel')}:</label>
            <div className={style.input_wrap}>
              <input
                type='number'
                className={`${style.main} ${
                  balanceError ? style.errorNumber : ''
                }`}
                name='amount'
                placeholder=''
                value={cashierInfo.amount}
                onChange={handleSetAmount}
              ></input>
              <div className={style.additional}>
                <TravelingoIcon />
              </div>
            </div>
            <div
              className={`${style.commentary} ${
                balanceError ? style.commentaryError : ''
              }`}
            >
              {balanceError
                ? 'Insufficient funds'
                : `~ ${cashierInfo.amount.toFixed(2)} €`}
            </div>
          </div>
          <div className={style.row}>
            <button
              onClick={handleSubmit}
              disabled={!isValid}
              className={style.btn}
            >
              {t('SendMainButton')}
            </button>
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  )
}

export default TransferPage
