import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isTransactionError: false,
  isLoading: false,
  transactionInfo: {
    amount: '',
  },
  recipient: {},
  cashierInfo: {
    recipient: {
      walletAddress: '',
    },
    amount: '',
    price: '',
  },
}

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setTransactionAmount: (state, action) => {
      state.transactionInfo.amount = action.payload
    },
    setAmount: (state, action) => {
      state.cashierInfo.amount = action.payload
    },
    setRecipient: (state, action) => {
      state.recipient = action.payload
    },
    clearCashier: (state) => {
      state.cashierInfo = {}
    },
    setTransactionError: (state, action) => {
      state.isTransactionError = action.payload
    },
    setCashierRecipient(state, action) {
      state.cashierInfo.recipient = action.payload
    },
    setCashierAmount(state, action) {
      state.cashierInfo.amount = action.payload
    },
    setCashierPrice(state, action) {
      state.cashierInfo.price = action.payload
    },
  },
})

export const {
  setIsLoading,
  setAmount,
  setRecipient,
  setTransactionAmount,
  setTransactionError,
  setCashierAmount,
  setCashierRecipient,
  setCashierPrice,
  clearCashier,
} = transactionSlice.actions

export default transactionSlice.reducer
