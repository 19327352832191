import { useEffect } from 'react'
import style from './CoinsBalance.module.scss'
import { ReactComponent as TrvIcon } from './images/trv.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo, getUserWallet } from '../../../hooks/getUserInfo'
import { setUser, setWallet } from '../../../store/userSlice'
import { t } from 'i18next'

const tg = window.Telegram.WebApp

const CoinsBalance = () => {
  const balance = useSelector((state) => state.user.wallet.balance)
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()

  const loadUser = async () => {
    try {
      const user = await getUserInfo(
        tg.initDataUnsafe.user.username,
        tg.initDataUnsafe.user.id
      )
      dispatch(setUser(user))
    } catch (error) {
      console.log(error)
    }
  }

  const loadWallet = async () => {
    try {
      const wallet = await getUserWallet(user.wallet_address)
      if (wallet.status === 'ok') {
        dispatch(setWallet(wallet.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!user.token_api) {
      loadUser()
    }
  }, [])

  useEffect(() => {
    if (user.wallet_address) {
      loadWallet()
    }
  }, [user.wallet_address])

  return (
    <div className={style.wrap}>
      <div className={style.row}>
        <div className={style.title}>{t('coinsBalanceTitle')}:</div>
        <div className={style.count}>
          <span>{balance.toFixed(2)}</span>
          <TrvIcon className={style.icon} />
        </div>
      </div>
      <div className={style.row}>
        <div className={style.count_in_fiat}>
          ~ {Number(balance).toFixed(2)} €
        </div>
      </div>
    </div>
  )
}

export default CoinsBalance
