import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
} from 'react-share'
import style from './ShareLinks.module.scss'
import ShareLink from './ShareLink/ShareLink'

const ShareLinks = () => {
  return (
    <div className={style.shareLinks}>
      <ShareLink
        ImportedComponent={TelegramShareButton}
        ImportedIcon={TelegramIcon}
        label='Telegram'
      />
      <ShareLink
        ImportedComponent={VKShareButton}
        ImportedIcon={VKIcon}
        label='VKontakte'
      />
      <ShareLink
        ImportedComponent={ViberShareButton}
        ImportedIcon={ViberIcon}
        label='Viber'
      />
      <ShareLink
        ImportedComponent={TwitterShareButton}
        ImportedIcon={TwitterIcon}
        label='Twitter'
      />
      <ShareLink
        ImportedComponent={EmailShareButton}
        ImportedIcon={EmailIcon}
        label='Email'
      />
      <ShareLink
        ImportedComponent={FacebookShareButton}
        ImportedIcon={FacebookIcon}
        label='Facebook'
      />
    </div>
  )
}

export default ShareLinks
