import './App.css'
import { Routes, Route, useLocation } from 'react-router-dom'
import MainPage from './components/MainPage/MainPage'
import GetPage from './components/GetPage/GetPage'
import SpendPage from './components/SpendPage/SpendPage'
import SendPage from './components/SendPage/SendPage'
import CompletedPage from './components/CompletedPage/CompletedPage'
import NotificationsPage from './components/NotificationsPage/NotificationsPage'
import TransferPage from './components/SpendPage/TransferPage/TransferPage'
import NotificationsItemPage from './components/NotificationsItemPage/NotificationsItemPage'
import TransferConfirm from './components/SpendPage/TransferConfirm/TransferConfirm'
import CompletedSpendPage from './components/SpendPage/CompletedSpendPage/CompletedSpendPage'
import { AnimatePresence } from 'framer-motion'

import './static/fonts.scss'
import './i18n'

function App() {
  const location = useLocation()

  return (
    <div className='app' style={{ overflowX: 'hidden' }}>
      <AnimatePresence initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<MainPage />} />
          <Route path='/getpage' element={<GetPage />} />
          <Route path='/spendpage' element={<SpendPage />} />
          <Route path='/sendpage' element={<SendPage />} />
          <Route path='/spendpage/confirm' element={<TransferConfirm />} />
          <Route path='/spendpage/transfer' element={<TransferPage />} />
          <Route
            path='/spendpage/transaction-sent'
            element={<CompletedSpendPage />}
          />
          <Route path='/transaction-sent' element={<CompletedPage />} />
          <Route path='/requests' element={<NotificationsPage />} />
          <Route path='/requests/:id' element={<NotificationsItemPage />} />
        </Routes>
      </AnimatePresence>
    </div>
  )
}

export default App
