import style from './ActivityItem.module.scss'
import { format } from 'date-fns'
import { motion } from 'framer-motion'
import RecentActivityIcon from '../RecentActivityIcon/RecentActivityIcon'
import { ReactComponent as TrvIcon } from './images/trv.svg'
import { t } from 'i18next'

const ActivityItem = ({
  isRequest,
  amount,
  date,
  from_name,
  to_name,
  isReceived,
  message,
  transactionType,
  status,
}) => {
  return (
    <motion.div
      initial={{
        y: -20,
        opacity: 0,
        transform: 'scaleY(0)',
        transformOrigin: 'top',
      }}
      animate={{
        y: 0,
        opacity: 1,
        transform: 'scaleY(1)',
        transformOrigin: 'top',
      }}
      exit={{
        y: -20,
        opacity: 0,
        transform: 'scaleY(0)',
        transformOrigin: 'top',
      }}
      className={`${style.item} ${isRequest ? style.isRequest : ''}`}
    >
      <div className={style.left}>
        <div className={style.image}>
          <RecentActivityIcon type={transactionType} isReceived={isReceived} />
        </div>
        <div className={style.transactionInfo}>
          <span className={style.main}>
            {isReceived ? `${from_name}` : `${to_name}`}
          </span>
          <span className={style.secondary}>
            {status === 'cancel' ? 'Canceled ' : ''}
            {transactionType.toLowerCase() === 'transfer'
              ? t('TransferTypeLabel')
              : ''}
            {transactionType.toLowerCase() === 'payment'
              ? t('PaymentTypeLabel')
              : ''}
            {transactionType.toLowerCase() === 'cashback'
              ? t('CashbackTypeLabel')
              : ''}
          </span>
        </div>
      </div>
      <div className={style.right}>
        <div
          className={`${style.count} ${
            transactionType === 'payment'
              ? style.payment
              : isReceived
              ? style.pozitive
              : style.negative
          }`}
        >
          {isReceived ? '+' : '-'} {amount} <TrvIcon />
        </div>
        <div className={style.date}>{format(date * 1000, 'd.MM.yyyy')}</div>
      </div>
    </motion.div>
  )
}

export default ActivityItem
