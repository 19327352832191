import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          BackButtonLabel: 'Back',
          coinsBalanceTitle: 'My Coin balance',
          GetMainButton: 'Get',
          SpendMainButton: 'Spend',
          SendMainButton: 'Send',
          RecentActivityPlaceholder: 'No recent activity found',
          MainPageRecentActivityLoadingLabel: 'Recent activity is loading',
          SettingsTitle: 'Settings',
          LanguageTitle: 'Language',

          GetPageTitle: 'Show the code for crediting coins',
          GetPageWalletNumberLabel: 'Wallet number',
          GetPageUserIdLabel: 'User ID',
          GetPageShareTitle: 'Choose one of the following share methods',
          GetPageShareLabel: 'Share',
          GetPageCopyLabel: 'Copy',
          GetPageCopiedLabel: 'Copied!',

          DebitingRequestLabel: 'Debit requests',
          DebitingRequestLabelSingle: 'Debit request',
          EmptyRequestsPlaceholder: "You don't have any requests",
          LoadingRequestsPlaceholder: 'Requests are loading',

          SendPageRecipientLabel: 'Send to',
          SendPageRecipientInput: 'Scan the code or enter the wallet number',
          SendPageAmountLabel: 'Enter the amount',
          SendPageRecipientError: 'User not found',
          SendPageRecipientSubmitLabel: 'Send',

          RecentActivityTitle: 'Recent activity',
          TransferTypeLabel: 'Transfer',
          PaymentTypeLabel: 'Payment',
          CashbackTypeLabel: 'Cashback',

          NoCommissionLabel: 'No comission',
          WaitingForDebitingLabel: 'Waiting for debiting',
          ThanksLabel:
            'Thank you for using our services. The coins have been successfully deducted.',
          ToMainLabel: 'To main',
          ConfirmLabel: 'Confirm',
          ConfirmTransactionLabel: 'Confirm the transaction to debit points',
          InsufficientFundsLabel: 'Insufficient funds',
        },
      },
      ru: {
        translations: {
          BackButtonLabel: 'Назад',
          coinsBalanceTitle: 'Мой баланс',
          GetMainButton: 'Получить',
          SpendMainButton: 'Потратить',
          SendMainButton: 'Отправить',
          RecentActivityPlaceholder: 'Недавняя активность отсутствует',
          MainPageRecentActivityLoadingLabel: 'Загрузка недавней активности',
          SettingsTitle: 'Настройки',
          LanguageTitle: 'Язык',

          GetPageTitle: 'Показать код для зачисления монет',
          GetPageWalletNumberLabel: 'Номер кошелька',
          GetPageUserIdLabel: 'ID пользователя',
          GetPageShareTitle: 'Выберите один из предложенных методов',
          GetPageShareLabel: 'Поделиться',
          GetPageCopyLabel: 'Скопировать',
          GetPageCopiedLabel: 'Скопировано!',

          DebitingRequestLabel: 'Запросы на списание',
          DebitingRequestLabelSingle: 'Запрос на списание',
          EmptyRequestsPlaceholder: 'Запросы отсутствуют',
          LoadingRequestsPlaceholder: 'Загрузка запросов',

          SendPageRecipientLabel: 'Послать (кому)',
          SendPageRecipientInput: 'Отсканируйте код или введите номер кошелька',
          SendPageAmountLabel: 'Введите сумму',
          SendPageRecipientError: 'Пользователь не найден',
          SendPageRecipientSubmitLabel: 'Отправить',

          RecentActivityTitle: 'Недавняя активность',
          TransferTypeLabel: 'Перевод',
          PaymentTypeLabel: 'Платеж',
          CashbackTypeLabel: 'Кэшбэк',

          NoCommissionLabel: 'Без комиссии',
          WaitingForDebitingLabel: 'Ожидание списания',
          ThanksLabel:
            'Благодарим Вас за использование нашего сервиса. Монеты были успешно списаны.',
          ToMainLabel: 'На главную',
          ConfirmLabel: 'Подтвердить',
          ConfirmTransactionLabel:
            'Подтвердите транзакцию для списания средств',
          InsufficientFundsLabel: 'Недостаточно средств',
        },
      },
      sk: {
        translations: {
          BackButtonLabel: 'Späť',
          coinsBalanceTitle: 'Môj finančný zostatok',
          GetMainButton: 'Získať',
          SpendMainButton: 'Míňať',
          SendMainButton: 'Odoslať',
          RecentActivityPlaceholder: 'Nebola nájdená žiadna nedávna aktivita',
          MainPageRecentActivityLoadingLabel: 'Nedávna aktivita sa Načítava',
          SettingsTitle: 'Nastavenie',
          LanguageTitle: 'Jazyk',

          GetPageTitle: 'Zobrazte kód  pre pripísanie finančných kreditov',
          GetPageWalletNumberLabel: 'Číslo e-peňaženky',
          GetPageUserIdLabel: 'ID používateľa',
          GetPageShareTitle: 'Vyberte jednu z nasledujúcich metód zdieľania',
          GetPageShareLabel: 'Zdieľať',
          GetPageCopyLabel: 'Kopírovať',
          GetPageCopiedLabel: 'Skopírovaný!',

          DebitingRequestLabel: 'Žiadosti o odpísanie',
          DebitingRequestLabelSingle: 'Žiadosť o odpísanie',
          EmptyRequestsPlaceholder: 'Nemáte žiadne požiadavky',
          LoadingRequestsPlaceholder: 'Žiadosti sa načítavajú',

          SendPageRecipientLabel: 'Odoslať na účet',
          SendPageRecipientInput:
            'Naskenujte kód alebo zadajte číslo e-peňaženky',
          SendPageAmountLabel: 'Zadajte sumu',
          SendPageRecipientError: 'Používateľ nebol nájdený',
          SendPageRecipientSubmitLabel: 'Odoslať',

          RecentActivityTitle: 'Nedávna aktivita',
          TransferTypeLabel: 'Prevod',
          PaymentTypeLabel: 'Úhrada',
          CashbackTypeLabel: 'Cashback',

          NoCommissionLabel: 'Bez poplatku',
          WaitingForDebitingLabel: 'Čakanie na odpísanie',
          ThanksLabel:
            'Ďakujeme, že využívate naše služby. Platobné prostriedky boli úspešne stiahnuté z účtu.',
          ToMainLabel: 'Na hlavnú stránku',
          ConfirmLabel: 'Potvrdiť',
          ConfirmTransactionLabel: 'Potvrdite transakciu na stiahnutie bodov',
          InsufficientFundsLabel: 'Nedostatočné finančné prostriedky',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
