import style from './NotificationsItemPage.module.scss'
import BackBtn from '../common/BackBtn/BackBtn'
import CoinsBalance from '../common/CoinBalance/CoinsBalance'
import RequestInfo from './RequestInfo/RequestInfo'
import RequestHint from './RequestHint/RequestHint'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { useEffect, useState } from 'react'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const NotificationsItemPage = () => {
  const user = useSelector((state) => state.user.user)
  const [requestInfo, setRequestInfo] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [step, setStep] = useState('wait')
  const navigate = useNavigate()
  const { id } = useParams()

  const loadItem = async () => {
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/user/get_detail_transaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token_api}`,
          },
          params: {
            user_token: user.token_api,
            user_id: user.user_id,
          },
        }
      )
      setRequestInfo(data)
    } catch (error) {
      console.log(error)
    }
  }

  const confirmRequest = async () => {
    setStep('loading')
    try {
      const { data } = await axios.post(
        `https://sc.travelingo.pro/api/submit_bill?transaction_id=${id}`
      )
      if (data.status === 'error') {
        setStep('error')
        setErrorMessage(data.data.error)
      } else {
        setStep('done')
      }
    } catch (error) {
      console.log(error)
      setStep('error')
    }
  }

  const redirectToMain = () => {
    navigate('/')
  }

  useEffect(() => {
    if (user.token_api) {
      loadItem()
    }
  }, [user.token_api])

  return (
    <PageTransitionWrapper side='left'>
      <div className={style.notificationsItemPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <RequestInfo
          step={step}
          amount={requestInfo.amount}
          cashierName={requestInfo.to_name}
        />
        <RequestHint step={step} errorMessage={errorMessage} />
        <button
          className={style.notificationsItemPageSubmit}
          onClick={step === 'wait' ? confirmRequest : redirectToMain}
          disabled={step === 'loading'}
        >
          {step === 'wait' ? t('ConfirmLabel') : t('ToMainLabel')}
        </button>
      </div>
    </PageTransitionWrapper>
  )
}

export default NotificationsItemPage
