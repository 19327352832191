import { useEffect, useState } from 'react'
import BackBtn from '../common/BackBtn/BackBtn'
import style from './GetPage.module.scss'
import image from './images/flag.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { QRCode } from 'react-qrcode-logo'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../../hooks/getUserInfo'
import { setUser } from '../../store/userSlice'
import ShareLinks from './ShareLinks/ShareLinks'
import { ReactComponent as CrossIcon } from './images/cross.svg'
import { ReactComponent as CopyIcon } from './images/copy-icon.svg'
import { ReactComponent as ShareIcon } from './images/share-icon.svg'
import { AnimatePresence, motion } from 'framer-motion'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'
import i18n from '../../i18n'

const tg = window.Telegram.WebApp

const GetPage = () => {
  const user = useSelector((state) => state.user.user)
  const [isCopied, setIsCopied] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const loadUser = async () => {
    try {
      const user = await getUserInfo(
        tg.initDataUnsafe.user.username,
        tg.initDataUnsafe.user.id
      )
      dispatch(setUser(user))
    } catch (error) {
      console.log(error)
    }
  }

  const handleCopy = () => {
    if (!isCopied) {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleClickOutside = () => {
    if (isModalOpen) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    if (!user?.wallet_address || !user?.user_id) {
      loadUser()
    }
  }, [])

  return (
    <PageTransitionWrapper side='bottom'>
      <div className={style.wrap}>
        <div className={style.header}>
          <BackBtn importedStyle={style.backButton} />
        </div>
        <div className={style.content} onClick={handleClickOutside}>
          <div className={style.title}>{t('GetPageTitle')}</div>
          <div className={style.qr}>
            <QRCode
              value={`trv://wallet?address=${user.wallet_address}`}
              size={250}
              logoImage={image}
              logoHeight={50}
              logoWidth={50}
              logoOpacity={1}
              removeQrCodeBehindLogo={true}
              enableCORS={true}
              qrStyle='squares'
              eyeRadius={6}
              id={'logo_id'}
            />
            <span className={style.walletQrTitle}>
              {t('GetPageWalletNumberLabel')}:
            </span>
            <p className={style.walletQrCode}>{user.wallet_address}</p>
          </div>
          <div className={style.address}>
            <span className={style.address__label}>
              {t('GetPageUserIdLabel')}:
            </span>
            <span className={style.address__number}>{user.user_id}</span>
          </div>
          <div className={style.footer}>
            <button onClick={handleOpenModal} className={style.share}>
              <ShareIcon />
              {t('GetPageShareLabel')}
            </button>
            <CopyToClipboard onCopy={handleCopy} text={user.wallet_address}>
              <button
                className={`${style.copy} ${isCopied ? style.copied : ''}`}
              >
                <CopyIcon />
                <span>
                  {isCopied ? t('GetPageCopiedLabel') : t('GetPageCopyLabel')}
                </span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <AnimatePresence>
          {isModalOpen ? (
            <motion.div
              className={style.modal}
              initial={{ bottom: '-100px', opacity: 0 }}
              animate={{ bottom: 0, opacity: 1 }}
              exit={{ bottom: '-100px', opacity: 0 }}
            >
              <button
                className={style.modalCloseButton}
                onClick={handleCloseModal}
              >
                <CrossIcon className={style.closeIcon} />
              </button>
              <ShareLinks />
              <span className={style.modalDescription}>
                {t('GetPageShareTitle')}
              </span>
            </motion.div>
          ) : (
            ''
          )}
        </AnimatePresence>
      </div>
    </PageTransitionWrapper>
  )
}

export default GetPage
