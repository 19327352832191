import { useSelector } from 'react-redux'
import style from './ShareLink.module.scss'

const ShareLink = ({ ImportedComponent, ImportedIcon, label }) => {
  const user = useSelector((state) => state.user.user)

  return (
    <ImportedComponent className={style.shareLink} url={user.wallet_address}>
      <ImportedIcon style={{ borderRadius: '50%' }} />
      <span className={style.shareLinkName}>{label}</span>
    </ImportedComponent>
  )
}

export default ShareLink
