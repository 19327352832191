import React from 'react'
import style from './RequestHint.module.scss'
import { t } from 'i18next'

const RequestHint = ({ step, errorMessage }) => {
  return (
    <div className={style.requestHint}>
      <span className={style.requestHintText}>
        {step === 'loading'
          ? t('WaitingForDebitingLabel')
          : step === 'done'
          ? t('ThanksLabel')
          : step === 'error'
          ? errorMessage
          : t('ConfirmTransactionLabel')}
      </span>
    </div>
  )
}

export default RequestHint
