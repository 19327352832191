import { useEffect, useState } from 'react'
import style from './NotificationsPage.module.scss'
import BackBtn from '../common/BackBtn/BackBtn'
import CoinsBalance from '../common/CoinBalance/CoinsBalance'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRecentActivity,
  getUserInfo,
  getUserWallet,
} from '../../hooks/getUserInfo'
import {
  setMoreWaitTransactions,
  setNotificationsIsLoading,
  setUser,
  setWaitTransactions,
  setWallet,
} from '../../store/userSlice'
import ActivityItem from '../MainPage/RecentActivity/ActivityItem/ActivityItem'
import { AnimatePresence, motion } from 'framer-motion'
import LinkActivityWrapper from '../MainPage/RecentActivity/LinkActivityWrapper/LinkActivityWrapper'
import { setRequestAmount, setRequestRecipient } from '../../store/requestSlice'
import { Link } from 'react-router-dom'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const transactionsLoadStep = 10
const tg = window.Telegram.WebApp

const NotificationsPage = () => {
  const user = useSelector((state) => state.user.user)
  const notificationsCount = useSelector(
    (state) => state.user.user.wait_transaction_count
  )
  const activity = useSelector((state) => state.user.waitTransactions)
  const dispatch = useDispatch()
  const [fromTransaction, setFromTransaction] = useState(0)
  const [toTransaction, setToTransaction] = useState(9)

  const loadUser = async () => {
    try {
      const user = await getUserInfo(
        tg.initDataUnsafe.user.username,
        tg.initDataUnsafe.user.id
      )
      dispatch(setUser(user))
    } catch (error) {
      console.log(error)
    }
  }

  const loadWallet = async () => {
    try {
      const wallet = await getUserWallet(user.wallet_address)
      if (wallet.status === 'ok') {
        dispatch(setWallet(wallet.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadRecentActivity = async () => {
    dispatch(setNotificationsIsLoading(true))
    try {
      const body = {
        user_id: user.user_id,
        token_api: user.token_api,
        wallet_address: user.wallet_address,
        status: 'wait',
        from: fromTransaction,
        to: toTransaction,
      }
      const activity = await getRecentActivity(body)
      dispatch(setWaitTransactions(activity))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setNotificationsIsLoading(false))
    }
  }

  const loadMoreRequests = async () => {
    setFromTransaction((prevState) => prevState + transactionsLoadStep)
    setToTransaction((prevState) => prevState + transactionsLoadStep)
    dispatch(setNotificationsIsLoading(true))
    try {
      const body = {
        user_id: user.user_id,
        token_api: user.token_api,
        wallet_address: user.wallet_address,
        status: 'wait',
        from: fromTransaction + transactionsLoadStep,
        to: toTransaction + transactionsLoadStep,
      }
      const activity = await getRecentActivity(body)
      dispatch(setMoreWaitTransactions(activity))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setNotificationsIsLoading(false))
    }
  }

  useEffect(() => {
    if (!user.wait_transaction_count) {
      loadUser()
    }
  }, [])

  useEffect(() => {
    if (user.wallet_address) {
      loadWallet()
    }
  }, [user.wallet_address])

  useEffect(() => {
    if (user.wallet_address) {
      loadRecentActivity()
    }
  }, [user])

  return (
    <PageTransitionWrapper side='right'>
      <div className={style.notifications}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <span className={style.title}>
          {activity.isLoading
            ? t('LoadingRequestsPlaceholder')
            : `${
                activity.transactions.length > 1
                  ? t('DebitingRequestLabel')
                  : t('DebitingRequestLabelSingle')
              } (${notificationsCount})`}
        </span>
        <AnimatePresence>
          {activity.transactions.length > 0 ? (
            <motion.div
              initial={{
                y: -20,
                opacity: 0,
                transform: 'scaleY(0)',
                transformOrigin: 'top',
              }}
              animate={{
                y: 0,
                opacity: 1,
                transform: 'scaleY(1)',
                transformOrigin: 'top',
              }}
              exit={{
                y: -20,
                opacity: 0,
                transform: 'scaleY(0)',
                transformOrigin: 'top',
              }}
            >
              {activity.transactions.map((activityItem) => {
                return (
                  <LinkActivityWrapper
                    key={activityItem.id}
                    link={`/requests/${activityItem.id}`}
                  >
                    <ActivityItem
                      isRequest
                      amount={activityItem.amount}
                      from_name={activityItem.from_name}
                      to_name={activityItem.to_name}
                      to_address={activityItem.to_address}
                      from_address={activityItem.from_address}
                      date={activityItem.date}
                      isReceived={activityItem.isReceived}
                      message={activityItem.message}
                      transactionType={activityItem.transaction_type}
                    />
                  </LinkActivityWrapper>
                )
              })}
            </motion.div>
          ) : (
            <span className={style.emptyPlaceholder}>
              {t('EmptyRequestsPlaceholder')}
            </span>
          )}
        </AnimatePresence>
        {user.wait_transaction_count > activity.transactions.length &&
        !activity.isLoading ? (
          <>
            <div className={style.splitter} />
            <button onClick={loadMoreRequests} className={style.loadMoreButton}>
              Load more
            </button>
          </>
        ) : (
          ''
        )}
      </div>
    </PageTransitionWrapper>
  )
}

export default NotificationsPage
