import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as GetIcon } from './images/get-icon.svg'
import { ReactComponent as SpendIcon } from './images/spend-icon.svg'
import { ReactComponent as SendIcon } from './images/send-icon.svg'
import style from './MainBtns.module.scss'
import { t } from 'i18next'

const MainBtns: FC = () => {
  return (
    <div className={style.MainBtns}>
      <Link to='/getpage' className={style.MainBtn}>
        <div className={style.image}>
          <GetIcon />
        </div>
        <span className={style.text}>{t('GetMainButton')}</span>
      </Link>
      <Link to='/spendpage' className={style.MainBtn}>
        <div className={style.image}>
          <SpendIcon />
        </div>
        <span className={style.text}>{t('SpendMainButton')}</span>
      </Link>
      <Link to='/sendpage' className={style.MainBtn}>
        <div className={style.image}>
          <SendIcon />
        </div>
        <span className={style.text}>{t('SendMainButton')}</span>
      </Link>
    </div>
  )
}

export default MainBtns
