import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  wallet: {
    balance: 0,
  },
  recentActivity: {
    isLoading: false,
    transactions: [],
  },
  waitTransactions: {
    isLoading: false,
    transactions: [],
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setWallet: (state, action) => {
      state.wallet = action.payload
    },
    setRecentActivity: (state, action) => {
      state.recentActivity.transactions = action.payload
    },
    setRecentActivityIsLoading: (state, action) => {
      state.recentActivity.isLoading = action.payload
    },
    setNotificationsIsLoading: (state, action) => {
      state.waitTransactions.isLoading = action.payload
    },
    setWaitTransactions: (state, action) => {
      state.waitTransactions.transactions = action.payload
    },
    setMoreWaitTransactions: (state, action) => {
      state.waitTransactions.transactions = [
        ...state.waitTransactions.transactions,
        ...action.payload,
      ]
    },
  },
})

export const {
  setUser,
  setWallet,
  setRecentActivity,
  setRecentActivityIsLoading,
  setWaitTransactions,
  setMoreWaitTransactions,
  setNotificationsIsLoading,
} = userSlice.actions

export default userSlice.reducer
