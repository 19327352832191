import { FC, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import CoinsBalance from '../../common/CoinBalance/CoinsBalance'
import BackBtn from '../../common/BackBtn/BackBtn'
import { ReactComponent as LoadingIcon } from './images/loading-icon.svg'
import style from './CompletedSpendPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CrossIcon } from './images/red-cross.svg'
import { ReactComponent as GreenIcon } from './images/green-icon.svg'
import { motion } from 'framer-motion'
import { setAmount, setRecipient } from '../../../store/transactionSlice'
import PageTransitionWrapper from '../../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const CompletedSpendPage: FC = () => {
  const isLoading = useSelector((state: any) => state.transaction.isLoading)
  const isTransactionWithError = useSelector(
    (state: any) => state.transaction.isTransactionError
  )
  const dispatch = useDispatch()
  const [URLSearchParams] = useSearchParams()

  const amount = URLSearchParams.get('amount')
  const pointId = URLSearchParams.get('pointId')

  useEffect(() => {
    return () => {
      dispatch(setRecipient({}))
      dispatch(setAmount(null))
    }
  }, [])

  return (
    <PageTransitionWrapper side='left'>
      <div className={style.completedPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <div className={style.content}>
          <div className={style.logo}>
            {isLoading ? (
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, ease: 'linear', duration: 3 }}
              >
                <LoadingIcon />
              </motion.div>
            ) : isTransactionWithError ? (
              <CrossIcon className={style.redCross} />
            ) : (
              <GreenIcon />
            )}
          </div>
          <div className={style.title}>{pointId}</div>
          <div className={style.amount}>{Number(amount).toFixed(2)}</div>
          <div className={style.amount_in_fiat}>
            ~ {Number(amount).toFixed(2)} €
          </div>
          <div className={style.commentary}>{t('NoCommissionLabel')}</div>
        </div>
        <div className={style.footer}>
          <div className={style.footer__text}>
            {isLoading ? t('WaitingForDebitingLabel') : t('ThanksLabel')}
          </div>
        </div>
        <button disabled={isLoading} className={style.btn_block}>
          {isLoading ? (
            <span className={style.btn}>{t('ToMainLabel')}</span>
          ) : (
            <Link to='/' className={style.btn}>
              {t('ToMainLabel')}
            </Link>
          )}
        </button>
      </div>
    </PageTransitionWrapper>
  )
}

export default CompletedSpendPage
