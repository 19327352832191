import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as TrvIcon } from './images/trv.svg'
import { ReactComponent as CoinIcon } from './images/coin.svg'
import { ReactComponent as BellIcon } from './images/bell-icon.svg'
import { ReactComponent as SettingsIcon } from './images/settings-icon.svg'
import { ReactComponent as CloseIcon } from './images/exit.svg'
import AnimatedNumber from 'react-awesome-animated-number'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { t } from 'i18next'

import 'react-awesome-animated-number/dist/index.css'
import style from './CoinsBalance.module.scss'
import i18n from '../../../i18n'

const tg = window.Telegram.WebApp

const CoinsBalance = ({ isLoading }) => {
  const balance = useSelector((state) => state.user.wallet.balance)
  const notificationsCount = useSelector(
    (state) => state.user.user.wait_transaction_count
  )
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()

  const handleCloseDrawer = () => {
    setIsOpen(false)
  }

  const handleOpenDrawer = () => {
    setIsOpen(true)
  }

  const handleClickListener = (event) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      handleCloseDrawer()
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    window.location.reload(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener)

    return () => {
      document.removeEventListener('mousedown', handleClickListener)
    }
  }, [])

  return (
    <div className={style.coin_balance}>
      <div className={style.header}>
        <div className={style.title}>{t('coinsBalanceTitle')}:</div>
      </div>
      <div className={style.content}>
        <div className={style.left}>
          <CoinIcon />
          <div className={style.countWrapper}>
            {isLoading ? (
              <div className={style.moneyLoader}>
                <span>0.00</span>
              </div>
            ) : (
              <>
                <AnimatedNumber
                  style={{
                    fontFamily: 'Barlow',
                    fontWeight: 600,
                    color: '#000',
                  }}
                  value={balance.toFixed(2)}
                  size={48}
                  duration={500}
                />
              </>
            )}
          </div>
          <TrvIcon className={style.currency} />
        </div>

        <span
          className={`${style.currency_fiat} ${
            isLoading ? style.is_loading : ''
          }`}
        >
          ~ {balance.toFixed(2)} €
        </span>
      </div>
      <button className={style.settings} onClick={handleOpenDrawer}>
        <SettingsIcon />
      </button>
      <Link to='/requests' className={style.notification}>
        <BellIcon />
        {notificationsCount ? (
          <div className={style.notification__new}>
            <span className={style.notification__new__number}>
              {notificationsCount}
            </span>
          </div>
        ) : (
          ''
        )}
      </Link>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            className={style.modal}
            initial={{ top: '-100px', opacity: 0 }}
            animate={{ top: 0, opacity: 1 }}
            exit={{ top: '-100px', opacity: 0 }}
            ref={ref}
          >
            <div className={style.modalTitle}>
              <span>{t('SettingsTitle')}</span>
              <button className={style.modalClose} onClick={handleCloseDrawer}>
                <CloseIcon className={style.modalCloseIcon} />
              </button>
            </div>
            <span className={style.blockHeader}>{t('LanguageTitle')}</span>
            <ul className={style.languagesList}>
              <li className={style.language}>
                <button
                  className={`${style.languageButton} ${
                    i18n.language === 'en' ? style.languageButtonActive : ''
                  }`}
                  onClick={() => changeLanguage('en')}
                >
                  English
                </button>
              </li>
              <li className={style.language}>
                <button
                  className={`${style.languageButton} ${
                    i18n.language === 'sk' ? style.languageButtonActive : ''
                  }`}
                  onClick={() => changeLanguage('sk')}
                >
                  Slovenčina
                </button>
              </li>
              <li className={style.language}>
                <button
                  className={`${style.languageButton} ${
                    i18n.language === 'ru' ? style.languageButtonActive : ''
                  }`}
                  onClick={() => changeLanguage('ru')}
                >
                  Русский
                </button>
              </li>
            </ul>
          </motion.div>
        ) : (
          ''
        )}
      </AnimatePresence>
    </div>
  )
}

export default React.memo(CoinsBalance)
