import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CoinsBalance from '../common/CoinBalance/CoinsBalance'
import BackBtn from '../common/BackBtn/BackBtn'
import { ReactComponent as LoadingIcon } from './images/loading-icon.svg'
import style from './CompletedPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CrossIcon } from './images/red-cross.svg'
import { ReactComponent as GreenIcon } from './images/green-icon.svg'
import { motion } from 'framer-motion'
import { setAmount, setRecipient } from '../../store/transactionSlice'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'
import { t } from 'i18next'

const CompletedPage: FC = () => {
  const transaction = useSelector(
    (state: any) => state.transaction.transactionInfo
  )
  const isLoading = useSelector((state: any) => state.transaction.isLoading)
  const recipient = useSelector((state: any) => state.transaction.recipient)
  const isTransactionWithError = useSelector(
    (state: any) => state.transaction.isTransactionError
  )
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setRecipient({}))
      dispatch(setAmount(null))
    }
  }, [])

  return (
    <PageTransitionWrapper side='right'>
      <div className={style.completedPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <div className={style.content}>
          <div className={style.logo}>
            {isLoading ? (
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, ease: 'linear', duration: 3 }}
              >
                <LoadingIcon />
              </motion.div>
            ) : isTransactionWithError ? (
              <CrossIcon className={style.redCross} />
            ) : (
              <GreenIcon />
            )}
          </div>
          <div className={style.title}>
            {recipient.tg_username
              ? recipient.tg_username
              : recipient.email
              ? recipient.email
              : ''}
          </div>
          <div className={style.amount}>
            {Number(transaction.amount).toFixed(2)}
          </div>
          <div className={style.amount_in_fiat}>
            ~ {Number(transaction.amount).toFixed(2)} €
          </div>
          <div className={style.commentary}>{t('NoCommissionLabel')}</div>
        </div>
        <div className={style.footer}>
          <div className={style.footer__text}>
            {isLoading ? t('WaitingForDebitingLabel') : t('ThanksLabel')}
          </div>
        </div>
        <button disabled={isLoading} className={style.btn_block}>
          {isLoading ? (
            <span className={style.btn}>{t('ToMainLabel')}</span>
          ) : (
            <Link to='/' className={style.btn}>
              {t('ToMainLabel')}
            </Link>
          )}
        </button>
      </div>
    </PageTransitionWrapper>
  )
}

export default CompletedPage
