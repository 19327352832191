import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from './images/chevron.svg'
import style from './BackBtn.module.scss'
import { t } from 'i18next'

interface BackBtnProps {
  inversColor?: boolean
  importedStyle?: any
}

const BackBtn: FC<BackBtnProps> = ({ inversColor, importedStyle }) => {
  const navigate = useNavigate()

  const handleGoBackBtn = () => {
    navigate(-1)
  }
  return (
    <button className={style.btn} onClick={() => handleGoBackBtn()}>
      <ArrowIcon className={`${importedStyle} ${style.icon}`} />
      <span className={style.text}>{t('BackButtonLabel')}</span>
    </button>
  )
}

export default BackBtn
