import { motion } from 'framer-motion'

const PageTransitionWrapper = ({ children, side }) => {
  return (
    <motion.div
      initial={
        side === 'top'
          ? { y: '-100%' }
          : side === 'bottom'
          ? { y: '100%' }
          : side === 'left'
          ? { x: '-100%' }
          : { x: '100%' }
      }
      animate={
        side === 'top'
          ? { y: '0%' }
          : side === 'bottom'
          ? { y: '0%' }
          : side === 'left'
          ? { x: '0%' }
          : { x: '0%' }
      }
      transition={{ duration: 0.35, ease: 'easeOut' }}
      exit={{ opacity: 1 }}
      style={{ position: 'absolute', left: '16px', right: '16px' }}
    >
      {children}
    </motion.div>
  )
}

export default PageTransitionWrapper
