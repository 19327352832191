import CoinsBalance from '../common/CoinBalance/CoinsBalance'
import BackBtn from '../common/BackBtn/BackBtn'
import RequestDebiting from './RequestDebiting/RequestDebiting'
import style from './SpendPage.module.scss'
import { QrReader } from 'react-qr-reader'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo, getUserWallet } from '../../hooks/getUserInfo'
import { setUser, setWallet } from '../../store/userSlice'
import {
  setCashierAmount,
  setCashierPrice,
  setCashierRecipient,
} from '../../store/transactionSlice'
import { useNavigate } from 'react-router-dom'
import PageTransitionWrapper from '../common/PageTransitionWrapper/PageTransitionWrapper'

const tg = window.Telegram.WebApp

const SpendPage = () => {
  const user = useSelector((state) => state.user.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loadUser = async () => {
    try {
      const user = await getUserInfo(
        tg.initDataUnsafe.user.username,
        tg.initDataUnsafe.user.id
      )
      dispatch(setUser(user))
    } catch (error) {
      console.log(error)
    }
  }

  const loadWallet = async () => {
    try {
      const wallet = await getUserWallet(user.wallet_address)
      if (wallet.status === 'ok') {
        dispatch(setWallet(wallet.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSuccessScanner = (data) => {
    const url = new URL(data)
    const params = new URLSearchParams(url.search)
    const amount = params.get('amount')
    const price = params.get('price')
    const point = params.get('pointId')
    if (point && !amount && !price) {
      navigate(
        `/spendpage/transfer?pointId=${point}&amount=${amount}&price=${price}`
      )
    }
    if (point && price && amount) {
      navigate(
        `/spendpage/confirm?pointId=${point}&amount=${amount}&price=${price}`
      )
    }
    dispatch(setCashierAmount(params.get('amount')))
    dispatch(setCashierRecipient(params.get('pointId')))
    dispatch(setCashierPrice(params.get('price')))
  }

  useEffect(() => {
    if (!user.token_api) {
      loadUser()
    }
  }, [])

  useEffect(() => {
    if (user.wallet_address) {
      loadWallet()
    }
  }, [user.wallet_address])

  return (
    <PageTransitionWrapper side='bottom'>
      <div className={style.spendPage}>
        <div className={style.header}>
          <BackBtn />
          <CoinsBalance />
        </div>
        <div className={style.list_actions}>
          <RequestDebiting />
        </div>
        <div className={style.content}>
          <QrReader
            ViewFinder={() => (
              <>
                <div
                  style={{
                    top: '50px',
                    left: '50px',
                    position: 'absolute',
                    height: '50px',
                    width: '50px',
                    zIndex: 999,
                    borderTop: '5px solid white',
                    borderLeft: '5px solid white',
                  }}
                />
                <div
                  style={{
                    top: '50px',
                    right: '50px',
                    position: 'absolute',
                    height: '50px',
                    width: '50px',
                    zIndex: 999,
                    borderTop: '5px solid white',
                    borderRight: '5px solid white',
                  }}
                />
                <div
                  style={{
                    bottom: '50px',
                    left: '50px',
                    position: 'absolute',
                    height: '50px',
                    width: '50px',
                    zIndex: 999,
                    borderBottom: '5px solid white',
                    borderLeft: '5px solid white',
                  }}
                />
                <div
                  style={{
                    bottom: '50px',
                    right: '50px',
                    position: 'absolute',
                    height: '50px',
                    width: '50px',
                    zIndex: 999,
                    borderBottom: '5px solid white',
                    borderRight: '5px solid white',
                  }}
                />
              </>
            )}
            constraints={{ facingMode: 'environment' }}
            onResult={(result, error) => {
              if (!!result) {
                handleSuccessScanner(result?.text)
              }
              if (!!error) {
                console.log(error?.text)
              }
            }}
          />
        </div>
      </div>
    </PageTransitionWrapper>
  )
}

export default SpendPage
